import Title from '../../../../layout/Title';
import VerticalBarsChart from '../../../components/Chart';
import { News } from './News';
import { About } from './About';
import { Panel } from '../../../../layout/Panel';
import PieChartWithNeedle from '../../../../layout/components/PieChartWithNeedle';

export const OverviewTab =({esgScore, riskScore, financialScore, news, company, loading, stockData}) => {
        const data = [
        { name: 'A', value: 20, color: '#FF0000' }, // Red
        { name: 'B', value: 20, color: '#FF7F00' }, // Orange
        { name: 'C', value: 20, color: '#FFFF00' }, // Blue
        { name: 'D', value: 20, color: '#7FFF00' }, // Light Green
        { name: 'E', value: 20, color: '#00CC00' }, // Green
    ];
    const settings = {
        width: 150,
        height: 150,
        startAngle: -110,
        endAngle: 110
    };
    // convest range -8 to +8 to 0 to 100
    const finPercentage = (financialScore + 8) * 100 / 16;

    const totalData = [
        { name: 'ESG', value: esgScore },
        { name: '(ESG)', value: 100-esgScore },
        { name: 'Financial', value:  finPercentage },
        { name: '(Financial)', value: 100-finPercentage },
        { name: 'Risk', value: riskScore },
        { name: '(Risk)', value: 100-riskScore },
    ]
    const esgScoreRel = Math.round(esgScore * .04 - 2) 
    const financialScoreRel = Math.round(financialScore * .04 - 2)
    const riskScoreRel = Math.round(riskScore * .04 - 2)
    const totalScore = esgScoreRel + financialScoreRel + riskScoreRel;
    return(
        <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-4">
                <Panel>
                    <div className="lg:flex">
                        <div className="flex flex-col">
                            <div className="text-center font-bold">Total Score {totalScore}</div>
                            <PieChartWithNeedle data={data} width={240} height={240} value={(totalScore *.24 + 2) *25}/>
                        </div>
                    </div>
                </Panel>
                <Panel>
                    <Title>Stock Price</Title>
                    <div className="pt-4 w-full">
                        <VerticalBarsChart
                            title={`↑ ${company.name} (${company.symbol}) stock price ($)`} 
                            data={stockData}
                        />
                    </div>
                </Panel>
            </div>
            <div className="lg:flex lg:flex-row">
                <div className='lg:w-2/3'>
                <News company={company} loading={loading}/>
                </div>
                <div className='lg:w-1/3 lg:ml-4' >
                <About company={company}/>
                </div>
            </div>
        </div>
    )
}