import querystring from 'query-string';
import config from "./../config";
import { getCookie } from "./AuthProvider";
import axios from 'axios';


const apiUrl = config.apiGateway.URL + config.apiGateway.apiPrefix;

const createHeadersFromOptions = (options) => {
    const requestHeaders = (options.headers ||
        new Headers({
            Accept: 'application/json',
        }));
    if (
        !requestHeaders.has('Content-Type') &&
        !(options && (!options.method || options.method === 'GET')) &&
        !(options && options.body && options.body instanceof FormData)
    ) {
        requestHeaders.set('Content-Type', 'application/json');
    }
    if (options.user && options.user.authenticated && options.user.token) {
        requestHeaders.set('Authorization', options.user.token);
    }

    return requestHeaders;
};

const httpClient = (url, options = {}) => {

    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = getCookie('token');
    options.headers.set('Authorization', `Bearer ${token}`);

    const requestHeaders = createHeadersFromOptions(options);

    return fetch(url, { ...options, headers: requestHeaders })
        .then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        )
        .then(({ status, statusText, headers, body }) => {
            let json;
            try {
                json = JSON.parse(body);
            } catch (e) {
                // not json, no big deal
            }
            if (status < 200 || status >= 300) {
                return Promise.reject(
                    {
                        error: new Error(
                            (json && json) || statusText,
                            status,
                            json
                        ),
                        message: json
                    }
                );
            }
            return Promise.resolve({ status, headers, body, json });
        });
};

export default {
    getList: (resource, params) => {
        let sort = {field: '', order: ''}
        let pagination = {page: 0, perPage: 0}
        if (params !== undefined && params.sort !== undefined) {
            sort = params.sort;
        }
        if (params !== undefined && params.pagination !== undefined) {
            pagination = params.pagination;
        }
        const { page, perPage } = pagination;
        const { field, order } = sort;
        const filter = params?.filter ? Object.fromEntries(
            Object.entries(params.filter).filter(([_, value]) => value !== null)
        ) : {};
        const query = {
            sort: JSON.stringify([field, order]),
            page: page,
            limit: perPage,
            filter: JSON.stringify(filter),
        }
        const qs = new URLSearchParams(filter).toString() + new URLSearchParams(params?.range).toString() + new URLSearchParams(params?.sort).toString()
        const url = `${apiUrl}/${resource}?${qs}&${querystring.stringify(query)}`;

        axios.get(url, {
                withCredentials: true, 
                withXSRFToken:true, 
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    }
                }
        ).then(response =>{
            return response;
        }).catch(err =>{
            console.log(err);
        });
    },

    getOne: (resource, params) => {
        return axios.get(`${apiUrl}/${resource}/${params.id}`, {
                withCredentials: true, 
                withXSRFToken:true, 
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    }
        });
    },
    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${querystring.stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${querystring.stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${querystring.stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, data) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(({ json }) => ({
            data: json
        })),

    delete: (resource, id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${querystring.stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
};
