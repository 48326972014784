import React, {useState} from 'react'
import {Link} from 'react-router-dom'
const cx = require('classnames')
import { Skeleton } from "@mui/material";

const editableRow = (row, keys) => {
    return (<tr>
        {keys.map((column) => {
            if (column.type === 'text') {
            return <td key={column.name} className="px-4">
                <input type="text" className='border' placeholder={column.name} onChange={handleInput(column.name)}/>
            </td>
            } else if (column.type === 'select') {
            return <td key={column.name} className="px-4">
                <select className='border' placeholder={column.name} onChange={handleInput(column.name)}>
                    <option value="">--Select--</option>
                    {column.options.map((option, i) => {
                        return <option key={i} value={option.value}>{option.label}</option>
                    }
                    )}
                </select>
            </td>
            }
        })}
        <td className="text-right">
            <button className="bg-green-500 text-white px-4 rounded" onClick={handleSave}>Save</button>
            <button className="bg-white text-gray-500 px-4 rounded" onClick={()=>setShowNewRow(false)}>Cancel</button>
        </td> 
    </tr>
    )
}



export default function Table({columns, keys, data, onDeleteRow, onAddRow, className, readOnly=false, loading=false}) {
    const[showNewRow, setShowNewRow] = useState(false)
    const[editMode, setEditMode] = useState(0)
    const[newRow, setNewRow] = useState({})
    const handleDelete = (id) => {
        return () => {
            onDeleteRow(id)
        }
    }

    const handleAdd = () => {
        setShowNewRow(true)
        setEditMode(0)
    }

    const handleEdit = (id) => {
        setEditMode(id)
    }

    const handleSave = () => {
        setShowNewRow(false)
        onAddRow(newRow)
    }

    const handleInput = (column) => {
        return (e) => {
            setNewRow({...newRow, [column]: e.target.value})
        }
    }

    return (
        <table className={cx("overflow-hidden", className)}>
            <thead>
                <tr className="gap-1 border-b border-general-40 mx-6 text-center">
                {columns && columns.map((column) => {
                    return <th key={column} className="font-normal text-general-80 text-sm pb-4 px-1">{column}</th>
                })}
                <th className="w-1/6"></th>
                <th className="w-1/8"></th>
                </tr>
            </thead>
            <tbody>
            {
                loading && (
                    Array.from({ length: 10 }, (_, index) => {
                        return (
                        <tr key={index} className="gap-1 border-b border-general-40 mx-6 text-center">
                            <td className="py-4 px-1" colSpan={columns.length}>
                                <Skeleton variant="rectangular" width="100%" height={40} sx={{ bgcolor: 'grey.50' }} />
                            </td>
                        </tr>)
                    }))
            }{
                !loading && data && data.map((row) => {
                    return <tr key={row.id} className="border-y border-general-40 hover:bg-general-30">
                    {keys.map((column) => {
                        if (column.component) {
                            return <td key={column.name} className={`py-2 px-1 text-left font-semibold overflow-auto ${column.class}`}>
                                {column.component(row[column.name])}
                            </td>
                        }
                        
                        
                        const value = row[column.name];
                        const conditionalClass = column.conditionalClass ? column.conditionalClass(value) : '';
                        return <td key={column.name} className={`py-2 px-1 text-left font-semibold overflow-auto ${column.class} ${conditionalClass}`}>
                            { column.link ? (
                                <Link to = {column.link.replace("{id}", row.id).replace("{symbol}", row.symbol)}>{column.format && column.format(row[column.name]) || row[column.name]}</Link>
                            ) :(
                                column.format && column.format(row[column.name]) || row[column.name]
                            )}
                        </td>
                    })}
                    {readOnly ||
                        <>
                            {editMode === row.id ?
                            <td>
                                <button className="bg-green-500 text-white px-4 mx-1 rounded" onClick={()=>handleEdit(row.id)}>Save</button>
                                <button className="bg-white text-gray-500 px-4 mx-1 rounded" onClick={()=>setEditMode(0)}>Cancel</button>
                            </td>
                            :
                            <td>
                                <button className="bg-yellow-500 text-white px-4 mx-1 rounded" onClick={()=>handleEdit(row.id)}>Edit</button>
                            </td>
                            }
                            <td>
                                <button className="bg-red-500 text-white px-4 mx-1 rounded" onClick={handleDelete(row.id)}>Delete</button>
                            </td>
                        </>}
                    </tr>
                })
            }
            {(showNewRow || readOnly) ||
            <tr>
                <td colSpan={columns.length + 2} className="text-right">
                    <button className="bg-green-500 text-white px-4 rounded" onClick={handleAdd}>Add</button>
                </td>
            </tr>
            }   
            {(showNewRow && !readOnly) &&
                <tr>
                    {keys.map((column) => {
                        if (column.type === 'text') {
                        return <td key={column.name} className="px-4">
                            <input type="text" className='border' placeholder={column.name} onChange={handleInput(column.name)}/>
                        </td>
                        } else if (column.type === 'select') {
                        return <td key={column.name} className="px-4">
                            <select className='border' placeholder={column.name} onChange={handleInput(column.name)}>
                                <option value="">--Select--</option>
                                {column.options.map((option, i) => {
                                    return <option key={i} value={option.value}>{option.label}</option>
                                }
                                )}
                            </select>
                        </td>
                        }
                    })}
                    <td className="text-right">
                        <button className="bg-green-500 text-white px-4 rounded" onClick={handleSave}>Save</button>
                        <button className="bg-white text-gray-500 px-4 rounded" onClick={()=>setShowNewRow(false)}>Cancel</button>
                    </td>
                    
                </tr>
            }
            </tbody>
        </table>
    )
    }