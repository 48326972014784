const dev = {
    apiGateway: {
        URL: "http://localhost:8093",
        apiPrefix: "/api"
    },
};

const prod = {
    apiGateway: {
        URL: "",
        apiPrefix: "/api"
    },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};
