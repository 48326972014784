import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import {getOne, deleteOne} from '../common/BackendProvider';
import {toast, ToastContainer} from "react-toastify";
import Button from '../layout/components/Button';

export const InvestmentProfilesEdit = () => {
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(true);
    let { id } = useParams(); 
   let navigate = useNavigate();
    useEffect(() => {
        setLoading(true)
        getOne("investment-profiles", {id: id}).then((response) => {
            setProfile(response.data.profile)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading investment profile", {})
                setLoading(false)
            }
        )
    },[location])

    const handleDelete = () => {
        deleteOne("investment-profiles", {id: id}).then((response) => {
            toast.success("Profile deleted successfully", {})
            navigate(`/investment_profiles`)
        }).catch((error) => {
            toast.error(error.message, {})
        })
    }

    return (
        <div className="p-8">
            <ToastContainer />
            <div className="flex justify-between">
                <div className="text-2xl font-bold mb-8">Investment Profile: {profile?.name}</div>
                <div className="flex items-center"></div>
            </div>
            <div>
                <Button variant="danger" onClick={handleDelete}>Delete</Button>
            </div>
        </div>
    )
}