import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, LabelList} from 'recharts';

const data = [
  { name: 'A', value: 20, color: '#FF0000' }, // Red
  { name: 'B', value: 20, color: '#FF7F00' }, // Orange
  { name: 'C', value: 20, color: '#FFFF00' }, // Yellow
  { name: 'D', value: 20, color: '#7FFF00' }, // Light Green
  { name: 'E', value: 20, color: '#00CC00' }, // Green
];

const CustomColor = ( value ) => {
    if (value >=2 ) {
        return '#00CC00'
    }
    if (value >=1 ) {
        return '#7FFF00'
    }
    if (value >=0 ) {
        return '#0000CD'
    }
    if (value >=-1 ) {
        return '#FF7F00'
    }
    return '#FF0000'
}

const BarChartLabel = ({ x, y, value }) => {
    const newX = x - 90;
    const newY = y - 5;
    return (
        <text
            x={newX}
            y={newY}
            fill="#666"
            textAnchor="start"
        >
            {value}
        </text>
    );
};
  
const CustomTooltip = ({ active, payload }) => {
    const label = payload[0]?.payload.name;
    if (active && payload && payload.length) {
        let value = payload[0].value;
        if (value == 0.1) {
            value = value -.1
        }
        return (
            <div className="bg-white p-2 border border-primary-30 shadow-shadow-base rounded">
                <p className="text-gray-600">{`${label}: ${value}`}</p>
            </div>
        );
    }

    return null;
}

export default function HorizontalBarChart ({name, value, width, height})  { 
    let value2 = value
    if (value == 0) {
        value2 = value +.1
    }
    return(
        <BarChart
            width={width}
            height={height}
            data={[{name: name, value2: value2, value: value}]}
            layout="vertical"
        >
            <XAxis type="number" dataKey="value" axisLine={true} domain={[-2, 2]} />
            <YAxis type="category" axisLine={true} display="none" />
            <Tooltip  content={<CustomTooltip/>}/>
            <Bar dataKey="value2" fill={CustomColor(value)} barSize={30}>
                <LabelList
                    dataKey="name"
                    position="top"
                    fill="#262626"
                    content={<BarChartLabel x={-1} y={-1} width={-1} value={-1} />}
                />
            </Bar>
        </BarChart>
)}
